<template>
  <section>
    <!-- calendario com opcao de ediçao-->
    <div class="col-md-12" v-if="ativar_calendario == 1">
      <div class="container_fluid_calendario">
        <div class="row container_calendario">
          <div class="col-md-12 mes_nome">
            {{ mes }}  <div class="numb-dias-letivos" title="Números de dias Letivos Mensal">{{item["numeros"][mes_marcar]}}</div>
          </div>
          <div
            class="col-md-1 container_tabela"
            v-for="n in item[mes_marcar]"
            :key="n.dia"
          >
            <div v-if="n.marcado == '0'">
              <button
                :disabled="!acesso.editar"
                type="button"
                class="btn calendario_dias"
                @click="marcar(mes_marcar, n.dia, n.marcado, item.id)"
              >
                <div class="font-semana">
                  {{ n.diaSemana }}
                </div>
                <div>
                  {{ n.dia }}
                </div>
              </button>
            </div>

            <div v-else>
              <button
                v-if="checarPrimeiroBimestreAberturaOuFechamento(mes_marcar, n)"
                :disabled="!acesso.editar"
                type="button"
                class="btn calendario_dias"
                :title="n.nome_exibir"
              >
                <div
                  class="font-semana"
                  :style="
                    'background-color: #' +
                    item.colorpicker_1b +
                    ';color: #fff;'
                  "
                >
                  {{ n.diaSemana }}
                </div>
                <div
                  :style="
                    'background-color: #' +
                    item.colorpicker_1b +
                    ';color: #fff;'
                  "
                >
                  {{ n.dia }}
                </div>
              </button>
              <button
                v-else-if="
                  checarSegundoBimestreAberturaOuFechamento(mes_marcar, n)
                "
                :disabled="!acesso.editar"
                type="button"
                class="btn calendario_dias"
                :title="n.nome_exibir"
              >
                <div
                  class="font-semana"
                  :style="
                    'background-color: #' +
                    item.colorpicker_2b +
                    ';color: #fff;'
                  "
                >
                  {{ n.diaSemana }}
                </div>
                <div
                  :style="
                    'background-color: #' +
                    item.colorpicker_2b +
                    ';color: #fff;'
                  "
                >
                  {{ n.dia }}
                </div>
              </button>
              <button
                v-else-if="
                  checarTerceiroBimestreAberturaOuFechamento(mes_marcar, n)
                "
                :disabled="!acesso.editar"
                type="button"
                class="btn calendario_dias"
                :title="n.nome_exibir"
              >
                <div
                  class="font-semana"
                  :style="
                    'background-color: #' +
                    item.colorpicker_3b +
                    ';color: #fff;'
                  "
                >
                  {{ n.diaSemana }}
                </div>
                <div
                  :style="
                    'background-color: #' +
                    item.colorpicker_3b +
                    ';color: #fff;'
                  "
                >
                  {{ n.dia }}
                </div>
              </button>
              <button
                v-else-if="
                  checarQuartoBimestreAberturaOuFechamento(mes_marcar, n)
                "
                :disabled="!acesso.editar"
                type="button"
                class="btn calendario_dias"
                :title="n.nome_exibir"
              >
                <div
                  class="font-semana"
                  :style="
                    'background-color: #' +
                    item.colorpicker_4b +
                    ';color: #fff;'
                  "
                >
                  {{ n.diaSemana }}
                </div>
                <div
                  :style="
                    'background-color: #' +
                    item.colorpicker_4b +
                    ';color: #fff;'
                  "
                >
                  {{ n.dia }}
                </div>
              </button>

              <button
                v-else
                :disabled="!acesso.editar"
                type="button"
                class="btn calendario_dias"
                @click="marcar(mes_marcar, n.dia, n.marcado, item.id)"
              >
                <div class="font-semana color_letivo">
                  {{ n.diaSemana }}
                </div>
                <div class="color_letivo">
                  {{ n.dia }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12" v-if="ativar_calendario == 0">
      <div class="container_fluid_calendario">
        <div class="row container_calendario">
          <div class="col-md-12 mes_nome">
            {{ mes }}
          </div>
          <div
            class="col-md-1 container_tabela"
            v-for="n in item[mes_marcar]"
            :key="n.dia"
          >
            <div v-if="n.marcado == '0'">
              <button
                :disabled="!acesso.editar"
                type="button"
                class="btn calendario_dias"
              >
                <div class="font-semana">
                  {{ n.diaSemana }}
                </div>
                <div>
                  {{ n.dia }}
                </div>
              </button>
            </div>

            <div v-else>
              <button
                v-if="checarPrimeiroBimestreAberturaOuFechamento(mes_marcar, n)"
                :disabled="!acesso.editar"
                type="button"
                class="btn calendario_dias"
                :title="n.nome_exibir"
              >
                <div
                  class="font-semana"
                  :style="
                    'background-color: #' +
                    item.colorpicker_1b +
                    ';color: #fff;'
                  "
                >
                  {{ n.diaSemana }}
                </div>
                <div
                  :style="
                    'background-color: #' +
                    item.colorpicker_1b +
                    ';color: #fff;'
                  "
                >
                  {{ n.dia }}
                </div>
              </button>
              <button
                v-else-if="
                  checarSegundoBimestreAberturaOuFechamento(mes_marcar, n)
                "
                :disabled="!acesso.editar"
                type="button"
                class="btn calendario_dias"
                :title="n.nome_exibir"
              >
                <div
                  class="font-semana"
                  :style="
                    'background-color: #' +
                    item.colorpicker_2b +
                    ';color: #fff;'
                  "
                >
                  {{ n.diaSemana }}
                </div>
                <div
                  :style="
                    'background-color: #' +
                    item.colorpicker_2b +
                    ';color: #fff;'
                  "
                >
                  {{ n.dia }}
                </div>
              </button>
              <button
                v-else-if="
                  checarTerceiroBimestreAberturaOuFechamento(mes_marcar, n)
                "
                :disabled="!acesso.editar"
                type="button"
                class="btn calendario_dias"
                :title="n.nome_exibir"
              >
                <div
                  class="font-semana"
                  :style="
                    'background-color: #' +
                    item.colorpicker_3b +
                    ';color: #fff;'
                  "
                >
                  {{ n.diaSemana }}
                </div>
                <div
                  :style="
                    'background-color: #' +
                    item.colorpicker_3b +
                    ';color: #fff;'
                  "
                >
                  {{ n.dia }}
                </div>
              </button>
              <button
                v-else-if="
                  checarQuartoBimestreAberturaOuFechamento(mes_marcar, n)
                "
                :disabled="!acesso.editar"
                type="button"
                class="btn calendario_dias"
                :title="n.nome_exibir"
              >
                <div
                  class="font-semana"
                  :style="
                    'background-color: #' +
                    item.colorpicker_4b +
                    ';color: #fff;'
                  "
                >
                  {{ n.diaSemana }}
                </div>
                <div
                  :style="
                    'background-color: #' +
                    item.colorpicker_4b +
                    ';color: #fff;'
                  "
                >
                  {{ n.dia }}
                </div>
              </button>

              <button
                v-else
                :disabled="!acesso.editar"
                type="button"
                class="btn calendario_dias"
                @click="marcar(mes_marcar, n.dia, n.marcado, item.id)"
              >
                <div class="font-semana color_letivo">
                  {{ n.diaSemana }}
                </div>
                <div class="color_letivo">
                  {{ n.dia }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    acesso: { type: Array, default: () => [] },
    item: { type: Array, default: () => [] },
    mes_marcar: "",
    mes: "",
    ativar_calendario: 0,
    componentKey: 0,
  },
  data() {
    return {};
  },

  methods: {
    marcar(mes, dia, marcado, calendario_letivo_id) {
      this.$emit("marcar", mes, dia, marcado, calendario_letivo_id);
    },
    converterParaData(mes, dia) {
      let mesNumero = "";
      if (mes == "janeiro") mesNumero = "01";
      if (mes == "fevereiro") mesNumero = "02";
      if (mes == "marco") mesNumero = "03";
      if (mes == "abril") mesNumero = "04";
      if (mes == "maio") mesNumero = "05";
      if (mes == "junho") mesNumero = "06";
      if (mes == "julho") mesNumero = "07";
      if (mes == "agosto") mesNumero = "08";
      if (mes == "setembro") mesNumero = "09";
      if (mes == "outubro") mesNumero = "10";
      if (mes == "novembro") mesNumero = "11";
      if (mes == "dezembro") mesNumero = "12";

      dia = String(dia);
      if (dia.length == 1) dia = "0" + dia;

      return String(this.item.ano) + "-" + String(mesNumero) + "-" + dia;
    },
    checarPrimeiroBimestreAberturaOuFechamento(mes, valores) {
      //verificar se a data do mes eh de algum inicio ou fim de bimestre
      if (this.item.b1_inicio != null) {
        if (
          this.converterParaData(mes, valores.dia) ==
          this.item.b1_inicio.substring(0, 10)
        ) {
          return true;
        }
      }
      if (this.item.b1_final != null) {
        if (
          this.converterParaData(mes, valores.dia) ==
          this.item.b1_final.substring(0, 10)
        ) {
          return true;
        }
      } else {
        return false;
      }
    },
    checarSegundoBimestreAberturaOuFechamento(mes, valores) {
      //verificar se a data do mes eh de algum inicio ou fim de bimestre
      if (this.item.b2_inicio != null) {
        if (
          this.converterParaData(mes, valores.dia) ==
          this.item.b2_inicio.substring(0, 10)
        ) {
          return true;
        }
      }
      if (this.item.b2_final != null) {
        if (
          this.converterParaData(mes, valores.dia) ==
          this.item.b2_final.substring(0, 10)
        ) {
          return true;
        }
      } else {
        return false;
      }
    },
    checarTerceiroBimestreAberturaOuFechamento(mes, valores) {
      //verificar se a data do mes eh de algum inicio ou fim de bimestre
      if (this.item.b3_inicio != null) {
        if (
          this.converterParaData(mes, valores.dia) ==
          this.item.b3_inicio.substring(0, 10)
        ) {
          return true;
        }
      }
      if (this.item.b3_final != null) {
        if (
          this.converterParaData(mes, valores.dia) ==
          this.item.b3_final.substring(0, 10)
        ) {
          return true;
        }
      } else {
        return false;
      }
    },
    checarQuartoBimestreAberturaOuFechamento(mes, valores) {
      //verificar se a data do mes eh de algum inicio ou fim de bimestre
      if (this.item.b4_inicio != null) {
        if (
          this.converterParaData(mes, valores.dia) ==
          this.item.b4_inicio.substring(0, 10)
        ) {
          return true;
        }
      }
      if (this.item.b4_final != null) {
        if (
          this.converterParaData(mes, valores.dia) ==
          this.item.b4_final.substring(0, 10)
        ) {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  async beforeMount() {},
});
</script>
